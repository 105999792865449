.clean-slate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  &__image {
    display: flex;
    img {
      height: 100px!important;
    }
  }
  &__text {
    margin-top: 0.6rem;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    text-align: center;
    width: auto;
  }
  &__action {
    margin-top: 0.8rem;
    display: flex;
    flex-direction: column;
  }
}