// Change Default Bulma Badge Shape (pill)
.badge[data-badge]:after{
  border-radius: 1rem;
  line-height: .5rem;
  top: 1px;
  right: -2px;
  padding: .3rem .5rem;
}

// Change Default Bulma Badge Colour
.badge.is-badge-primary[data-badge]::after{
  background: $blue;
  color: $white;
}