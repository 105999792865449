// Change Default Bulma Switch Colour
.switch[type=checkbox].is-primary:checked + label::before {
  background: $blue;
}

// Bulma right-to-left switches are wrongly implemented. This fixes it.
.switch[type=checkbox].is-rtl + label::after{
  left: auto;
  right: 1.75rem;
}
.switch[type=checkbox].is-primary:checked.is-rtl + label::after{
  left: auto;
  right: 0.25rem;
}