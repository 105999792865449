// Import Font
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro&display=swap');

// Import the initial variables
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/functions";

// Import your customized variables
@import "./partials/variables";

// Replace default Bulma fonts
$family-primary: $family-sans-serif;

// Replace default Bulma colours
$primary: $blue;
$primary-invert: findColorInvert($blue);

$link: $blue;
$link-invert: findColorInvert($blue);

$info: $blue;
$info-invert: findColorInvert($blue);

$success: $green;
$success-invert: findColorInvert($green);

$warning: $orange;
$warning-invert: findColorInvert($orange);

$danger: $red;
$danger-invert: findColorInvert($red);

$light: $white;
$light-inverse: $black;

$dark: $black;
$dark-inverse: $white;

// Import Bulma
@import "~bulma";
@import "~bulma-switch";
@import "~bulma-badge";

// Import Other
@import "ag-partials/order-colors";
@import "ag-partials/line-item-colors";
@import "ag-partials/pack-instructions-colors";

@import "~@ng-select/ng-select/themes/default.theme.css";

@import "./partials/badge";
@import "./partials/switch";
@import "./partials/clean-slate";