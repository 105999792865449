// Set your own initial variables
$family-sans-serif:'Maven Pro', sans-serif;

// Default Colours
$white: #fafafa;
$black: #111;
$red:#d23e3e;
$green: #2fbd76;
$blue: #3392FF;
$orange: #f68b1f;
$purple: #6b2468;